@import  '../../utils//Variables.scss';

$SEARCH_BOX_WIDTH: 470px;

.search-box-container {
  width: $SEARCH_BOX_WIDTH;
  margin: 6px 20px;
}

.search-box-control {
  background-color: $gray_light3;
  border: none;
  & > input {
    &, &::placeholder {
      color: $blue_dark2;
    }
  }
}

.search-box-suggestions {
  position: relative;
}

.search-box-suggestions-inner {
  position: absolute;
  top: 1px;
  background: white;
  width: 100%;
  border: 1px solid $gray_light3;
}

.search-suggestion-item {
  padding: 8px 5px;
  &:hover {
    background: rgb(243, 242, 241);
  }
  border-bottom: 1px solid $gray_light3;
}

.search-suggestion-item-line1 {
  font-size: 14px;
}

.search-suggestion-item-line2 {
  font-size: 12px;
  color: $gray_dark;
  font-weight: 400;
}

.search-suggestion-item-message {
  text-align: center;
  font-size: 14px;
  padding: 8px 5px;
  font-style: italic;
  font-weight: 500;
  cursor: default;
}