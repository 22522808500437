@import './Variables.scss';

.app {
  *:not(.ms-SearchBox-clearButton) > .ms-Button--default {
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: $default_box_shadow;
  }
  .ms-Button--default {    
    &.is-selected {
      background-color: $blue_dark;
      border: none;
      color: $white;
    }

    .ms-Button-label {
      font-weight: 500;
    }
  }
  .ms-Button--primary {
    background-color: $blue_light;
    border: none;
  }
  *:not(.ms-Pivot) > .ms-Button--action {
    color: $blue_light;
    font-weight: 700;
  }
  .ms-DetailsRow-cell {
    font-size: 14px;

    a {
      text-decoration: none;
    }
  }
  .ms-Label {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ms-Pivot {
    margin-bottom: 20px;

    .ms-Pivot-link.ms-Button {
      padding-left: 0;
      padding-right: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }
      
      &, .ms-Pivot-linkContent, .ms-Pivot-text {
        height: 24px;
        line-height: 17px;
      }

      &:hover {
        background-color: inherit;
      }

      &:not(.is-selected) {
        font-weight: 500;
        color: $blue_dark;

        &::before {
          display: none;
        }
      }

      &.is-selected {
        font-weight: 700;
        
        &::before {
          background-color: $orange_light;
          height: 3px;
          left: 0;
          right: 0;
        }
      }
    }
  }
}