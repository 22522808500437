@import '../../utils/Variables.scss';

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main {
    display: flex;
    flex-direction: row;
    height: calc(100vh - #{$header_height});
    position: relative;
    overflow: hidden;
}

.main-left {
    position: relative;
}

.content {
    position: relative;
    overflow-y: scroll;
    width: 100%;

    &.login {
        text-align: center;
        font-size: 24px;
        padding: 50px;
    }
}

.error-banner {
    position: absolute;
    z-index: 10;
}

.error-banner-content {
    background-color: rgb(253, 203, 207);
}