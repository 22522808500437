.dashboard {
    .welcome h1 {
        margin-bottom: 60px;
    }

    .dashboard-logo {
        max-height: 60px;
        max-width: 180px;
        margin-bottom: 20px;
    }

    .resources a {
        text-decoration: underline;
    }
}