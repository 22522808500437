@import  '../../utils//Variables.scss';
$INNER_WIDTH: 1100px;

.search-results-close {
  padding: 10px 15px;
}

.search-results-panel-scrollable-content {
  overflow-y: scroll;
  min-height: 100%;
}

.search-results-panel-content {
  padding: 0;
}

.search-results-section {
  padding-bottom: 20px;
  &:not(:last-child) {
    border-bottom: 5px solid $gray_light;    
  }
}

.search-results-inner {
  max-width: $INNER_WIDTH;
  margin: 0px auto;
  width: 100%;
}

.search-results-filters-container {
  
}

.search-results-filters-selected-container {
  max-width: 690px;
}

.search-results-filters-scope {
  width: 200px;
}

.search-results-filters-scopevalues {
  width: 400px;
}

.search-results-filters-clear {
  height: 32px;
}

.search-results-filters-deselect-button {
  height: 20px;
  width: 20px;
}

.search-results-filters-showhide-selected {
  height: 20px;
}

.search-result-item {
  padding: 15px 20px;
  margin: 0px -20px;
  em {
    font-weight: 700;
  }
  .search-result-item-title {
    color: $teal;
  }
  &:first-child {
    margin-top: 10px;
  }
  &:not(.search-result-item-shimmer):nth-child(even) {
    background-color: $gray_light2;
  }
}

.search-results-pagination-button {
  border-radius: 20px;
  padding: 10px;
  max-height: 30px;
  min-width: 40px;
  & > * {
    justify-content: center;
  }
}

.search-results-pagination-selected {
  background-color: $gray_light2;
}