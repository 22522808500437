@import '../../utils/Variables.scss';

.section {
    background: $white;
    padding: 20px;

    &:not(:first-child) {
        border-top: 5px solid $gray_light;
    }

    a h1 {
        color: $blue_light;
    }
}