.ig-doc {
  table {
    border-collapse: collapse;
  }

  div[role=tablist] {
    display: contents;
  }

  .confluenceTable {
    width: auto;
    overflow-x: scroll;
  }

  .confluenceTh, .confluenceTd {
    border: 1px solid #C1C7D0;
    padding: 7px 10px;
    vertical-align: top;
    text-align: left;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol, ol ol ol ol, ol ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol ol {
    list-style-type: decimal;
  }

  ol ol, ol ol ol ol ol, ol ol ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol ol ol {
    list-style-type: lower-alpha;
  }

  ol ol ol, ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol, ol ol ol ol ol ol ol ol ol ol ol ol {
    list-style-type: lower-roman;
  }
  span.variable.sdtm {
  	background-color: #D04437;
  	border-color: #D04437;
  	color: #FFF;
  }
  span.variable.cdash-sdtm {
  	background-color: #D04437;
  	border-color: #D04437;
  	color: #FFF;
  }

  span.variable {
	background: #FFF none repeat scroll 0% 0%;
	border: 1px solid #FFF;
	border-radius: 3px;
	display: inline-block;
	font-size: 11px;
	font-weight: bold;
	line-height: 99%;
	padding: 3px 5px 2px;
 }
 span.variable.no-sdtm {
    background-color: #FFF;
    border-color: #f8d3d1;
    color: #D04437;
  }
 ul.radio, ul.inline {
  list-style-type: none;
 }
 span.variable.cdash {
  background-color: #CCC;
  border-color: #CCC;
  color: #333;
 }
 .fieldInstructions, .fieldIssues {
  padding: 7px 10px;
  max-width: 25em;
  border: none;
 }
 .crf.field.hiddenField .fieldLabel, .crf.field.hiddenField .fieldInput {
  background-color: #f2f2f2;
 }
}
