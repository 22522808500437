/** COLOR PALLETE **/

$white: #FFFFFF;
$black: #000000;
$blue_dark: #0C4677;
$blue_dark2: #004578;
$blue_light: #0078D4;
$gray_dark: #434343;
$gray_light: #F5F5F5;
$gray_light2: #F5F8F9;
$gray_light3: #EBF2F7;
$gray_med: #E7E8E9;
$teal: #0F9999;
$orange_light: #EBA820;
$default_box_shadow: 0px 2px 4px #00000040;

/** LAYOUT **/
$header_height: 60px;