@import '../../utils/Variables.scss';

.list {    

    .list-top {
        display: flex;
        justify-content: flex-end;

        .list-filter-input { 
            width: 250px;
        }
    }

    .list-bottom {
        display: flex;
        justify-content: flex-end;   
        align-items: center;     
        margin-top: 10px;
        user-select: none;

        .list-pagination-pagesize {
            padding-right: 10px;
            display: flex;
            align-items: center;
    
            label {
                padding-right: 10px;
            }
        }
    
        .list-pagination-pagelabel {
            text-align: center;
            min-width: 100px;
        }
    
        .list-pagination-itemlabel {
            text-align: right;
            min-width: 120px;
            margin-right: 20px;
        }
    
        .list-pagination-icon {
            font-size: 24px;
            padding: 5px 15px;
            cursor: pointer;
            user-select: none;
    
            &:not(.disabled):hover {
                color: $blue_light;
            }
    
            &.disabled {
                cursor: default;
                color: $gray_med;
            }
        }
    }

    .list-panel-icon {
        user-select: none;
        position: relative;
        cursor: pointer;
        color: $blue_light;
        font-size: 20px;
        padding: 5px 10px;
        margin: -9px;
        top: 2px;

        &:hover {
            color: $blue_dark;
        }
    }

    .list-row {
        &.selected {
            background-color: $gray_light3;
        }
    }
}

.list-panel-layer {
    position: fixed;
    top: $header_height;
    bottom: 0px;
    right: 0px;
}

.list-panel {
    .list-panel-inner {
        padding: 30px 0;
    }
}
.list-panel-main {
    background: $gray_light2;
}