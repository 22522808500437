@import '../../utils/Variables.scss';

.header {
  height: $header_height;    
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 0px 20px #00000026;
  z-index: 1;
  background-color: $white;

  .header-left {
      display: flex;
      align-items: center;
      
      .header-logo {
          margin: 0 20px;
          max-height: 40px;
          max-width: 120px;
      }
  
      .site-title {
          font-size: 18px;
          font-weight: 500;
          display: inline;
          color: $blue_dark;
      }
  }

  .header-right {
      align-items: center;
      display: flex;
      margin: 0 20px;
      font-size: 16px;
      cursor: pointer;
  }

}