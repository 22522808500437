@import './utils/Variables.scss';

html, body, #root {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
  color: $blue_dark;
  line-height: 42px;
  font-size: 35px;
  font-weight: 700;
}

h2 {
  margin: 0;
  color: $blue_dark;
  line-height: 17px;
  font-size: 24px;
  font-weight: 500;
}

h3 {
  margin: 0;
  color: $gray_dark;
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
}

h4 {
  margin: 0 0 5px 0;
  color: $gray_dark;
  line-height: 17px;
  font-size: 14px;
  font-weight: 700;
}

ul {
  padding-left: 0px;
  list-style-type: none;
}

a {
  color: $blue_light;
  font-weight: 500;
  text-decoration: none;
}

.hidden {
  display: none;
}

.draft-status {
    color: red;
    font-weight: 700;
}