@import '../../utils/Variables.scss';

.sidepanel {
    background: $gray_light;
    box-sizing: border-box;
    padding: 20px;
    width: 220px;
    min-width: 220px;
    transition: width 400ms, min-width 400ms;
    overflow-x: hidden;

    .inner {
        min-width: 180px;
        overflow: hidden;
    }

    &.collapsed {
        width: 20px;
        min-width: 20px;  

        .inner {
            display: none;
        }      
    }

    .expander {
        position: absolute;
        right: 4px;
        font-weight: 700;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }



    .dashboard-link {
        text-decoration: none;
        display: block;
        padding-left: 1px;
        
        > span {
            padding: 0 5px;
        }
    }
} 