@import '../../utils/Variables.scss';

button.ms-Button.ms-Button--action.nav-expand-all {
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    color: $black;

    .nav-expand-all-icon {
        margin: 0;
        font-size: 14px;
        color: $gray_dark;
        padding-right: 2px;
    }
}
.navigation {
    margin-top: 10px;

    .nav-chevron-icon {
        left: 1px;
    }
    .nav-chevron-button {
        font-size: 14px;
        font-weight: 700;
        padding-left: 19px;
        border-bottom: none;
    }
    .nav-chevron-icon, 
    .nav-chevron-button {
        height: 17px;
        line-height: 17px;
    }
    .nav-group {
        margin-bottom: 15px;
    }
    .nav-group-content {
        margin-bottom: 0px;

        .navItems-72 {
            .navItems-72 {
                padding-left: 10px;
            }
        }
    }

    .nav-warning-icon {
        color: red;
    }

    .nav-composite-link {
        .nav-link {
            span {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }

        button.nav-link {
            color: $blue_dark2;
        }
        
        &, &.is-selected {
            .nav-link {
                height: 25px;
                line-height: 25px;
                color: $blue_light;
                font-weight: 500;
                padding-left: 14px;
                padding-right: 0px;

                .nav-warning-icon {
                    color: red
                }
            }
        }
        &:hover, &.is-selected {
            .nav-link {
                background-color: $gray_med;
            }
            .nav-link::after {
                display: none;
            }
        }
    }

    :has(~ ul) > a > span > div {
        color: $blue_dark2;
    }
} 

.nav-shimmer-wrapper {
    background-color: #dedede;
    .ms-ShimmerGap-root {
        background: $gray_light;
    }
}

.nav-shimmer-gradient {
    background: linear-gradient(to right, #dedede 0%, #ccc 50%, #dedede 100%) 0px 0px / 90% 100% no-repeat #dedede;
}
